<template>
  <div>
    <p class="text-center font-weight-bolder font-medium-5">
      {{ $t('Update Role') }}
    </p>
    <skeleton
      v-if="loader"
    />
    <div v-if="role.id">
      <error-alert :error="error" />
      <form-component
        :role="filterRole"
        @submit="submit"
        @cancel="clear"
      />
    </div>
    <div v-else-if="notFound">
      <component-not-found
        title="Role List"
        :button-link="{name: 'settings-users-role-list'}"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import Skeleton from '@/views/settings/users/roles/components/Skeleton.vue'
import FormComponent from '../components/FormComponent.vue'
import {onUnmounted} from "@vue/composition-api";

export default {
  name: 'RoleUpdate',
  components: {
    Skeleton,
    ErrorAlert,
    FormComponent,
  },
  data() {
    return {
      required,
      email,
      error: {},
      notFound: false,
      loader: false,
    }
  },
  computed: {
    role() {
      return this.$store.state[this.MODULE_NAME].role
    },
    roleList() {
      return this.$store.state.listModule.roleList
    },
    warehouseList() {
      return this.$store.state.listModule.warehouseList
    },
    filterRole() {
      const role = { ...this.role }
      role.permissions = this.role.permissions.map(per => per.id)

      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_ROLE_FORM_CLONE`, {
        ...this.role,
        permissions: role.permissions,
        is_active: role.is_active,
      })
      return role
    },
  },
  created() {
    this.loader = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit('listModule/SET_PERMISSION_LIST', [])
    this.$store.dispatch('listModule/permissionList')
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      this.notFound = false
      data.permissions = data.permissions.map(per => per.id)

      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {
        ...data,
        permissions: data.permissions,
        is_active: data.is_active,
      })
      this.loader = false
    }).catch(err => {
      console.log(err)
      this.notFound = true
    })
  },
  beforeDestroy() {
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_ROLE_FORM_CLONE`, [])
  },
  methods: {
    submit(data, resetPermissions) {
      this.error = {}
      this.sendNotification(this, data, `${this.MODULE_NAME}/update`)
        .then(() => {
          this.clear()
          this.$router.push({ name: 'settings-users-role-list' })
        })
        .catch(err => {
          this.error = err.response?.data
        })
        .finally(() => {
          resetPermissions()
        })
    },
    clear() {
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.$router.push({ name: 'settings-users-role-list' })
    },
    getProps() {
      return {
        model: this.role,
        fields: this.fields,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'roles'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style scoped></style>
